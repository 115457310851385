import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

import { resetStoredData } from "./profile.module";

import { getGraphQlErrorMessage } from "@/helpers/graphqlErrorHelpers";

const store = { namespaced: true };

//state
store.state = {
  error: null,
  transaction: null,
  loans: [],
  selectedLoan: null,
  cardResponse: null,
  subscription: null,
};

// Getters ====================
store.getters = {
  selectedLoanTransactions: (state) => {
    return [...((state.selectedLoan || {}).transactions || [])];
  },
  lastTransaction: (state, getters) => {
    if (getters.selectedLoanTransactions.length)
      return getters.selectedLoanTransactions[0];
    return null;
  },
  outstandingBalance: (state, getters) => {
    let { principal, fees, interests, amount_requested } =
      state.selectedLoan || {};
    principal = parseFloat(principal);
    const amount_owe =
      (principal || amount_requested) + (fees || 0) + (interests || 0);

    console.log("amount owe", amount_owe);

    const transactions = getters.selectedLoanTransactions;

    console.log("transactions", transactions);
    const _outstandingBalance = transactions
      .filter((x) => !x.cancelled && x.confirmed)
      .reduce((_balance, trx) => {
        _balance += trx.amount;
        return _balance;
      }, 0 - amount_owe);

    console.log("outstanddingBalance", _outstandingBalance);
    return _outstandingBalance;
  },

  anyCurrentLoan: (state, getters) => {
    return !!getters.mostRecentLoanId;
  },

  mostRecentLoanId: (state) => {
    const validStatuses = ["current", "in_review", "pending"];
    const get_ms = (x) => new Date(x).getTime();
    const most_recent_loan = state.loans
      .filter(
        (x) => !!x.date_applied && validStatuses.some((s) => s == x.status)
      )
      .reduce((_most_recent, _loan) => {
        if (
          !_most_recent.date_applied ||
          get_ms(_loan.date_applied) > get_ms(_most_recent.date_applied)
        )
          _most_recent = _loan;
        return _most_recent;
      }, {});
    console.log("mostRecentLoanId:--", most_recent_loan);
    return most_recent_loan.id;
  },

  isActiveLoan: (state) => {
    const validStatuses = ["current", "in_review"];
    return state.loans.some((x) => validStatuses.some((s) => s == x.status));
  },
};

// Mutations ==================
store.mutations = {
  SET_CARD_ERROR(state, error) {
    state.error = error;
  },
  SET_TRANSACTION(state, transaction) {
    state.transaction = transaction;
  },

  SET_LOAN(state, loan = null) {
    state.selectedLoan = loan;
    state.cardResponse = null;
  },

  SET_LOANS(state, loans) {
    state.loans = loans;
  },

  SET_CARD_RESP(state, cardResponse) {
    state.cardResponse = cardResponse;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

// Actions ====================
store.actions = {
  async getLoan({ commit, state }, id) {
    const {
      data: { getLoan: loan },
    } = await API.graphql(graphqlOperation(queries.getLoan, { id }));
    const loans = [...state.loans];
    const idx = loans.findIndex((x) => x.id === id);
    loans.splice(idx, 1, loan);
    resetStoredData("loans", loans);
    commit("SET_LOANS", loans);
    commit("SET_LOAN", loan);
  },

  async loadLoans({ commit }) {
    const {
      data: { loadLoans },
    } = await API.graphql(graphqlOperation(queries.loadLoans));
    resetStoredData("loans", loadLoans);
    commit("SET_LOANS", loadLoans);
    commit("SET_LOAN");
  },

  async createLoan({ commit, state, rootGetters }, loan) {
    const userid = rootGetters["account/userid"];
    const {
      data: { createLoan: newLoan },
    } = await API.graphql(
      graphqlOperation(mutations.createLoan, { loan, userid })
    );
    const loans = [...state.loans];
    loans.push(newLoan);
    loans.sort((a, b) => new Date(b.date_applied) - new Date(a.date_applied));
    resetStoredData("loans", loans);
    commit("SET_LOANS", loans);
    commit("SET_LOAN");
  },

  async withdrawLoan({ commit, state, rootGetters }, id) {
    const userid = rootGetters["account/userid"];
    const {
      data: { withdrawLoan: loan },
    } = await API.graphql(
      graphqlOperation(mutations.withdrawLoan, { id, userid })
    );
    const loans = [...state.loans];
    const idx = loans.findIndex((x) => x.id === id);
    loans.splice(idx, 1, loan);
    loans.sort((a, b) => new Date(b.date_applied) - new Date(a.date_applied));
    resetStoredData("loans", loans);
    commit("SET_LOANS", loans);
    commit("SET_LOAN");
  },

  async doCardTransaction(
    { commit, rootGetters },
    { loan_id, amount, cardCVV2, isAuth }
  ) {
    const userid = rootGetters["account/userid"];
    const {
      data: { doCardTransaction: cardResponse },
    } = await API.graphql(
      graphqlOperation(mutations.doCardTransaction, {
        loan_id,
        amount,
        cardCVV2,
        isAuth,
        userid,
      })
    );
    console.log("cardResponse", cardResponse);
    commit("SET_CARD_RESP", cardResponse);
  },

  monitorLoans({ commit, state, rootGetters }) {
    if (state.subscription) state.subscription.unsubscribe();
    const userid = rootGetters["account/userid"];
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdatedLoan, {
        userid,
      })
    ).subscribe({
      next: ({ value: { data, errors } }) => {
        if (errors) {
          const errorMsg = getGraphQlErrorMessage(errors);
          commit("SET_CARD_ERROR", new Error(errorMsg));
        } else {
          const loan = data.onUpdatedLoan;
          console.log("trx loan", loan);

          const loans = [...state.loans];

          let idx = loans.findIndex((x) => x.id === loan.id);
          if (idx < 0) idx = loans.length;
          loans.splice(idx, 1, loan);
          resetStoredData("loans", loans);
          console.log("loans", loans);
          commit("SET_LOAN", loan);
          commit("SET_LOANS", loans);
        }
      },
    });
    console.log("monitorLoans---subscription:", subscription);
    commit("SET_SUBSCRIPTION", subscription);
  },
};

export default store;
