import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
//import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const store = { namespaced: true };

//state
store.state = {
  template: null,
  templates: [],
  loading: false,
};

// Getters ====================
store.getters = {
  templateNames(state) {
    return state.templates.map((x) => x.templateName);
  },
};

// Mutations ==================
store.mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_TEMPLATE(state, template) {
    state.template = template;
  },

  SET_TEMPLATES(state, templates) {
    state.templates = templates || [];
  },
};

// Actions ====================
store.actions = {
  async listEmailTemplates({ commit }) {
    commit("SET_LOADING", true);
    const resp = await API.graphql(
      graphqlOperation(queries.listEmailTemplates)
    );
    console.log("listEmailTemplates-resp", resp);
    const {
      data: { listEmailTemplates: templates },
    } = resp;

    console.log("templates", templates);

    commit("SET_TEMPLATES", templates);
    commit("SET_LOADING", false);
  },

  async getEmailTemplate({ commit }, templateName) {
    commit("SET_LOADING", true);
    const {
      data: { getEmailTemplate: template },
    } = await API.graphql(
      graphqlOperation(queries.getEmailTemplate, { templateName })
    );
    console.log("template", template);
    commit("SET_TEMPLATE", template);
    commit("SET_LOADING", false);
  },

  async updateEmailTemplate(
    { commit, state },
    { html, text, subject, templateName }
  ) {
    commit("SET_LOADING", true);
    await API.graphql(
      graphqlOperation(mutations.updateEmailTemplate, {
        html,
        text,
        subject,
        templateName,
      })
    );
    const templates = [...state.templates];
    let idx = templates.findIndex((x) => x.templateName === templateName);
    if (idx == -1) idx = templates.length;
    templates.splice(idx, 1, { html, text, subject, templateName });
    commit("SET_TEMPLATES", templates);   
    commit("SET_LOADING", false);
  },
};

export default store;
