//import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

const store = { namespaced: true };

//state
store.state = {
  settings: [],
};

// Getters ====================
store.getters = {};

// Mutations ==================
store.mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
};

// Actions ====================
store.actions = {
  async getLoanSettings({ commit }) {
    const storageKey = "orenda-settings";
    const current_time = Date.now();
    let last_time = 0;
    let settings = {};
    const setting_url = this._vm.$getOrendaSettingsUrl();
    const str_data = localStorage.getItem(storageKey);
    if (str_data) {
      try {
        ({ settings, last_time } = JSON.parse(str_data));
        commit("SET_SETTINGS", settings);
        console.log("getLoanSettings commit from storage");
      } catch (err) {
        console.error("getLoanSettings", err);
      }
    }

    if (current_time - last_time > 60 * 60 * 1000) {
     
        console.log("getLoanSettings before fetching");

        const response = await fetch(setting_url);
        const _settings = await response.json();
        console.log("settings--------", _settings)
        settings = _settings;
        last_time = current_time;
        const data = { settings, last_time };
        localStorage.setItem(storageKey, JSON.stringify(data));
        commit("SET_SETTINGS", settings);
        console.log("getLoanSettings commit from api");
     
    }
  },

  async changeLoanSettings({ commit }, setting) {
    const {
      data: { changeLoanSettings: settings },
    } = await API.graphql(
      graphqlOperation(mutations.changeLoanSettings, { setting })
    );
    commit("SET_SETTINGS", settings);
  },
};

export default store;
