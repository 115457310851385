import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

import { resetStoredData } from "./profile.module";

import { getGraphQlErrorMessage } from "@/helpers/graphqlErrorHelpers";

const store = { namespaced: true };

//state
store.state = {
  loan: null,
  loans: [],
  applicant: null,
  applicants: [],
  cardData: null,
  subscription: null,
  loading: false,
};

// Getters ====================
store.getters = {};

// Mutations ==================
store.mutations = {
  SET_LOAN(state, loan) {
    state.loan = loan;
  },

  SET_LOANS(state, loans) {
    state.loans = loans;
  },

  SET_CARD_DATA(state, cardData) {
    state.cardData = cardData;
  },

  SET_APPLICANT(state, data) {
    state.applicant = data;
  },

  SET_APPLICANTS(state, data) {
    state.applicants = data.map((x) => ({
      userid: x.userid,
      given_name: x.given_name,
      family_name: x.family_name,
      gender: x.gender,
    }));
  },

  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

// Actions ====================
store.actions = {
  clearLoan({ commit }) {
    commit("SET_LOAN", null);
    commit("SET_APPLICANT", null);
  },
  async listAdminLoans({ commit, dispatch }) {
    console.log("listAdminLoans---start");
    commit("SET_LOADING", true);
    const {
      data: {
        listAdminLoans: { loans, personalInfos },
      },
    } = await API.graphql(graphqlOperation(queries.listAdminLoans));
    resetStoredData("allPersonalInfos", personalInfos);
    resetStoredData("allLoans", loans);
    commit("SET_APPLICANTS", personalInfos);
    commit("SET_LOANS", loans);
    commit("SET_LOADING", false);
    dispatch("monitorAllLoans");
  },

  async getAdminLoan({ commit }, id) {
    const {
      data: {
        getAdminLoan: { loan, personalInfo, cardData },
      },
    } = await API.graphql(graphqlOperation(queries.getAdminLoan, { id }));
    console.log("loan", loan);
    console.log("cardData", cardData);
    console.log("personalInfo", personalInfo);
    commit("SET_APPLICANT", personalInfo);
    commit("SET_CARD_DATA", cardData);
    commit("SET_LOAN", loan);
  },

  async adminUpdateLoan({ commit, state }, { id, userid, actions }) {
    const {
      data: { adminUpdateLoan: loan },
    } = await API.graphql(
      graphqlOperation(mutations.adminUpdateLoan, { id, userid, actions })
    );
    const loans = [...state.loans];
    const idx = loans.findIndex((x) => x.id === loan.id);
    loans.splice(idx, 1, loan);
    resetStoredData("allLoans", loans);
    commit("SET_LOANS", loans);
    commit("SET_LOAN", null);
  },

  async adminCreateLoanTransaction(
    { commit, state },
    {
      userid,
      loan_id,
      reference,
      linked_transaction_id,
      type,
      amount,
      currency,
    }
  ) {
    const entry = {
      reference,
      linked_transaction_id,
      type,
      amount,
      currency,
    };
    const {
      data: { doTransaction: loan },
    } = await API.graphql(
      graphqlOperation(mutations.doTransaction, { userid, loan_id, entry })
    );
    const loans = [...state.loans];
    const idx = loans.findIndex((x) => x.id === loan.id);
    loans.splice(idx, 1, loan);
    resetStoredData("allLoans", loans);
    commit("SET_LOANS", loans);
    commit("SET_LOAN", null);
  },

  monitorAllLoans({ commit, state }) {
    if (state.subscription) state.subscription.unsubscribe();

    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdatedAdminLoan)
    ).subscribe({
      next: ({ value: { data, errors } }) => {
        if (errors) {
          const errorMsg = getGraphQlErrorMessage(errors);
          commit("SET_CARD_ERROR", new Error(errorMsg));
        } else {
          const loan = data.onUpdatedAdminLoan;
          console.log("trx loan", loan);

          const loans = [...state.loans];

          let idx = loans.findIndex((x) => x.id === loan.id);
          if (idx < 0) idx = loans.length;
          loans.splice(idx, 1, loan);

          loans.sort(
            (a, b) =>
              new Date(b.date_applied).getTime() -
              new Date(a.date_applied).getTime()
          );

          console.log("loans", loans);
          resetStoredData("allLoans", loans);
          commit("SET_LOANS", loans);
        }
      },
    });
    console.log("monitorLoans---subscription:", subscription);
    commit("SET_SUBSCRIPTION", subscription);
  },
};

export default store;
