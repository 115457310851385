import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";

import { API, graphqlOperation } from "aws-amplify";

import { formaTabView, checkPersonalInfo } from "@/helpers/formatPersonInfo";

const storageKey = "orenda-assets";

export const resetStoredData = (key, value) => {
  const str_data = localStorage.getItem(storageKey);
  if (str_data) {
    const { assets, last_time } = JSON.parse(str_data);
    assets[key] = value;
    const data = { assets, last_time };
    localStorage.setItem(storageKey, JSON.stringify(data));
  }
};

const store = { namespaced: true };

//state
store.state = {
  document: null,
  personalInfo: {},
  isAdmin: false,
  loaded: false,
  hostedUrl: null,
  cardData: {},
  cardDataSubscription: null,
};

// Getters ====================
store.getters = {
  tabbedPersonalInfo: (state) => {
    return formaTabView(state.personalInfo);
  },
  checkPersonalInfo: (state, getters, rootState) => {
    const { attributes } = rootState.account.user || {};

    const { email, email_verified, phone_number, phone_number_verified } =
      attributes || {};
    const EmailAddress = email && email_verified;
    const PhoneNumber = phone_number && phone_number_verified;

    return {
      ...checkPersonalInfo(state.personalInfo),
      EmailAddress,
      PhoneNumber,
    };
  },

  invalidKeys: (state, getters) => {
    return Object.keys(getters.checkPersonalInfo).reduce(
      (_invalidKeys, key) => {
        if (!getters.checkPersonalInfo[key]) _invalidKeys.push(key);
        return _invalidKeys;
      },
      []
    );
  },
  CreditCardTransactionResults: (state) => {
    return (
      (state.cardData && state.cardData.CreditCardTransactionResults) || {}
    );
  },
};

// Mutations ==================
store.mutations = {
  SET_LOADED(state, loaded) {
    state.loaded = loaded;
  },
  SET_ADMIN(state, isAdmin) {
    state.isAdmin = isAdmin;
  },

  SET_PERSONAL_INFO(state, personalInfo) {
    state.personalInfo = personalInfo;
  },

  SET_DOCUMENT(state, document) {
    state.document = document;
  },

  SET_HOSTED_URL(state, url) {
    state.hostedUrl = url;
  },

  SET_MONITOR_CARD_SUBSCRIPTION(state, subscription) {
    state.cardDataSubscription = subscription;
  },

  SET_CARD_DATA(state, cardData) {
    state.cardData = cardData;
  },

  STOP_CARD_SUBSCRIPTION(state) {
    if (state.cardDataSubscription) state.cardDataSubscription.unsubscribe();
    state.hostedUrl = null;
  },
};

// Actions ====================
store.actions = {
  async getPersonalInfo({ commit }) {
    const {
      data: { loadPersonalInfo },
    } = await API.graphql(graphqlOperation(queries.loadPersonalInfo));
    resetStoredData("personalInfo", loadPersonalInfo);
    commit("SET_PERSONAL_INFO", loadPersonalInfo);
  },

  async getDocument({ commit }, key) {
    const {
      data: { getDocument },
    } = await API.graphql(graphqlOperation(queries.getDocument, { key }));
    commit("SET_DOCUMENT", getDocument);
  },

  async deleteDocument({ commit }, key) {
    const {
      data: { deleteDocument },
    } = await API.graphql(graphqlOperation(mutations.deleteDocument, { key }));
    resetStoredData("personalInfo", deleteDocument);
    commit("SET_PERSONAL_INFO", deleteDocument);
  },

  async loadAssets({ commit, dispatch }) {
    const parseAssets = (_assets) => {
      const {
        loans,
        personalInfo,
        isAdmin,
        cardData,
        allLoans,
        allPersonalInfos,
      } = _assets;
      console.log("loadAssets--isAdmin---", isAdmin);
      commit("SET_CARD_DATA", cardData);
      commit("loan/SET_LOANS", loans, { root: true });
      commit("SET_PERSONAL_INFO", personalInfo);
      commit("SET_ADMIN", isAdmin);
      commit("SET_LOADED", true);
      ///////////////////admin
      if (isAdmin) {
        commit("admin_loan/SET_APPLICANTS", allPersonalInfos, { root: true });
        commit("admin_loan/SET_LOANS", allLoans, { root: true });
        commit("admin_loan/SET_LOADING", false, { root: true });
        dispatch("admin_loan/monitorAllLoans", {}, { root: true });
      }
    };

    const current_time = Date.now();
    let last_time = 0;
    let assets = {};

    const str_data = localStorage.getItem(storageKey);
    if (str_data) {
      try {
        ({ assets, last_time } = JSON.parse(str_data));
        parseAssets(assets);
        console.log("loadAssets commit from storage");
      } catch (err) {
        console.error("loadAssets", err);
      }
    }

    ///expired after 5 minutes
    if (current_time - last_time > 5 * 60 * 1000) {
      const {
        data: { loadAssets: _assets },
      } = await API.graphql(graphqlOperation(queries.loadAssets));
      last_time = current_time;
      const data = { assets: _assets, last_time };
      localStorage.setItem(storageKey, JSON.stringify(data));
      parseAssets(_assets);
    }
  },

  async modifyPersonalInfo({ commit }, personalInfo) {
    const {
      data: { modifyPersonalInfo },
    } = await API.graphql(
      graphqlOperation(mutations.modifyPersonalInfo, { personalInfo })
    );
    resetStoredData("personalInfo", modifyPersonalInfo);
    commit("SET_PERSONAL_INFO", modifyPersonalInfo);
  },

  async cancelUserCard({ commit }, userid) {
    const {
      data: { cancelUserCard: cardData },
    } = await API.graphql(
      graphqlOperation(mutations.cancelUserCard, { userid })
    );

    // SET_CARD_DATA(state, cardData)
    resetStoredData("cardData", cardData);
    commit("SET_CARD_DATA", cardData);
  },

  async getHostedPaymentUrl({ commit }, id) {
    const {
      data: { getHostedPaymentUrl: hostedUrl },
    } = await API.graphql(
      graphqlOperation(queries.getHostedPaymentUrl, { id })
    );
    console.log("hostedUrl:---", hostedUrl);
    commit("SET_HOSTED_URL", hostedUrl);
  },

  monitorCardPreAuthConfirmation({ commit, rootGetters }) {
    const userid = rootGetters["account/userid"];
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCardData, {
        userid,
      })
    ).subscribe({
      next: (trxs) => {
        const cardData = trxs.value.data.onCardData;
        resetStoredData("cardData", cardData);
        commit("SET_CARD_DATA", cardData);
      },
    });
    console.log("monitorCardPreAuthConfirmation---subscription:", subscription);
    commit("SET_MONITOR_CARD_SUBSCRIPTION", subscription);
  },
};

export default store;
