import Vue from "vue";
import Vuex from "vuex";

import account from "./account.module";
import loan from "./loan.module";
import admin_loan from "./admin_loan.module";
import profile from "./profile.module";
import settings from "./settings.module";
import emailTemplate from "./emailTemplate.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { account, loan, profile, admin_loan, settings, emailTemplate },
});
