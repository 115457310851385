const aws_appsync_region = "us-east-1";
const aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";
const production_hostname = "orendafinancialservices.com";

const dev_ndpoint =
  "https://nv4bemjw4rabnnkqxyxe7xblke.appsync-api.us-east-1.amazonaws.com/graphql";

const prod_ndpoint =
  "https://mijug6jspralxgrqykkk7yqaiq.appsync-api.us-east-1.amazonaws.com/graphql";

const Auth = {
  identityPoolId: "us-east-1:ae6eb452-cb15-421c-b0e3-0279c239235a",
  region: "us-east-1",
  userPoolId: "us-east-1_drsznwbSa",
  userPoolWebClientId: "7j3lqpgdkimaoohldj9kl7toit",
};

const hostname = location.hostname;

//these might not be necessary
const dev_appsync_apiKey = "da2-wwhrtzuehjgc3dhtyc6k5nnblm";
const prod_appsync_apiKey = "da2-tcbsrgs4pzdv5orju4froahpim";

const validHostnames = [production_hostname, `www.${production_hostname}`];
const is_prod = validHostnames.some((x) => x === hostname);
const aws_appsync_apiKey = is_prod ? prod_appsync_apiKey : dev_appsync_apiKey;

const aws_appsync_graphqlEndpoint = is_prod ? prod_ndpoint : dev_ndpoint;

const awsSettings = {
  aws_appsync_graphqlEndpoint,
  aws_appsync_region,
  aws_appsync_authenticationType,
  aws_appsync_apiKey,
  Auth,
};

console.log("hostname", hostname);
export default awsSettings;
