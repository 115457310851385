/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCardData = /* GraphQL */ `
  subscription OnCardData($userid: String!) {
    onCardData(userid: $userid) {
      BillingDetails {
        BillToAddress
        BillToAddress2
        BillToCity
        BillToCountry
        BillToCounty
        BillToEmail
        BillToFirstName
        BillToLastName
        BillToMobile
        BillToState
        BillToTelephone
        BillToZipPostCode
      }
      BinCheckResults {
        BIN
        Brand
        Country
        Issuer
        Subtype
        Type
      }
      CreditCardTransactionResults {
        AVSResult
        AuthCode
        CVV2Result
        OriginalResponseCode
        PaddedCardNumber
        ReasonCode
        ReasonCodeDescription
        ReferenceNumber
        ResponseCode
        TokenizedPAN
      }
      FraudControlResults {
        FraudControlId
        FraudResponseCode
        ReasonCode
        ReasonCodeDesc
        ResponseCode
        Score
      }
      IPGeoLocationResults {
        City
        CountryLong
        CountryShort
        DomainName
        IPAddress
        IPResponseCode
        IPResponseCodeDescription
        ISP
        Latitude
        Longitude
        Region
        TimeZone
        ZipCode
      }
      MerchantId
      OrderNumber
      ShippingDetails {
        ShipToAddress
        ShipToAddress2
        ShipToCity
        ShipToCountry
        ShipToCounty
        ShipToEmail
        ShipToFirstName
        ShipToLastName
        ShipToMobile
        ShipToState
        ShipToTelephone
        ShipToZipPostCode
      }
      Signature
      SignatureMethod
      ThreeDSResponse {
        AuthenticationResult
        CAVV
        ECIIndicator
        TransactionStain
      }
      userid
    }
  }
`;
export const onUpdatedAdminLoan = /* GraphQL */ `
  subscription OnUpdatedAdminLoan {
    onUpdatedAdminLoan {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        loan_id
        reference
        type
      }
      type
      userid
    }
  }
`;
export const onUpdatedLoan = /* GraphQL */ `
  subscription OnUpdatedLoan($userid: String!) {
    onUpdatedLoan(userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        loan_id
        reference
        type
      }
      type
      userid
    }
  }
`;
