// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "../store/index.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/Index.vue"),
        },
        {
          path: "/about",
          name: "About",
          component: () => import("@/views/About.vue"),
          meta: { src: require("@/assets/about.jpg") },
        },
        {
          path: "/contact-us",
          name: "Contact",
          component: () => import("@/views/ContactUs.vue"),
          meta: { src: require("@/assets/contact.jpg") },
        },
        {
          path: "/faq",
          name: "FAQ",
          component: () => import("@/views/Faq.vue"),
          meta: { src: require("@/assets/pro.jpg") },
        },
        {
          path: "/legal",
          name: "Legal",
          component: () => import("@/views/Legal.vue"),
          meta: { src: require("@/assets/pro.jpg") },
        },

        {
          name: "Account",
          path: "/account/:target",
          component: () => import("@/views/Account.vue"),
          meta: { src: require("@/assets/pricing.jpg") },
          props: true,
        },

        /////////////////////////////////////////
        {
          name: "Dashboard",
          path: "/member/dashboard",
          component: () => import("@/views/member/Dashboard.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },
        {
          name: "Loans",
          path: "/member/loans",
          component: () => import("@/views/member/Loans.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },
        {
          name: "LoanApplication",
          path: "/member/loan-application",
          component: () => import("@/views/member/LoanApplication.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },
        {
          name: "Profile",
          path: "/member/profile",
          component: () => import("@/views/member/Profile.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },

        {
          name: "Card",
          path: "/member/card",
          component: () => import("@/views/member/Card.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },

        ////////////////////////////

        {
          name: "Admin",
          path: "/admin/",
          component: () => import("@/views/admin/Index.vue"),
          meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
        },

        //////////////////////////////

        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404.vue"),
        },
      ],
    },
  ],
});
router.onError((err) => {
  console.log("router err", err);
});
router.beforeEach((to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  const confirmIn = "/account/confirm-sign-in";
  const signIn = "/account/sign-in";
  if (to.path === confirmIn && from.path === signIn) {
    next();
  } else {
    store.dispatch("account/fetchUser").then(() => {
      const authorized = store.getters["account/authorized"];
      const isToPageMembership = to.matched.some((x) => x.meta.requiresAuth);
      if (isToPageMembership && !authorized) {
        next({ name: "Home" });
      } else if (!isToPageMembership && authorized && to.name === "Home") {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    });
  }
});

export default router;
