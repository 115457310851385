/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAdminLoan = /* GraphQL */ `
  query GetAdminLoan($id: ID!) {
    getAdminLoan(id: $id) {
      cardData {
        BillingDetails {
          BillToAddress
          BillToAddress2
          BillToCity
          BillToCountry
          BillToCounty
          BillToEmail
          BillToFirstName
          BillToLastName
          BillToMobile
          BillToState
          BillToTelephone
          BillToZipPostCode
        }
        BinCheckResults {
          BIN
          Brand
          Country
          Issuer
          Subtype
          Type
        }
        CreditCardTransactionResults {
          AVSResult
          AuthCode
          CVV2Result
          OriginalResponseCode
          PaddedCardNumber
          ReasonCode
          ReasonCodeDescription
          ReferenceNumber
          ResponseCode
          TokenizedPAN
        }
        FraudControlResults {
          FraudControlId
          FraudResponseCode
          ReasonCode
          ReasonCodeDesc
          ResponseCode
          Score
        }
        IPGeoLocationResults {
          City
          CountryLong
          CountryShort
          DomainName
          IPAddress
          IPResponseCode
          IPResponseCodeDescription
          ISP
          Latitude
          Longitude
          Region
          TimeZone
          ZipCode
        }
        MerchantId
        OrderNumber
        ShippingDetails {
          ShipToAddress
          ShipToAddress2
          ShipToCity
          ShipToCountry
          ShipToCounty
          ShipToEmail
          ShipToFirstName
          ShipToLastName
          ShipToMobile
          ShipToState
          ShipToTelephone
          ShipToZipPostCode
        }
        Signature
        SignatureMethod
        ThreeDSResponse {
          AuthenticationResult
          CAVV
          ECIIndicator
          TransactionStain
        }
        userid
      }
      loan {
        amount_requested
        borrower_type
        comments
        currency
        date_applied
        date_approved
        date_first_payment_due
        date_issued
        date_required
        days
        disbusement_date
        fees
        id
        installment
        interests
        payment_method
        periodType
        possible_new_statuses
        principal
        profile_certified
        status
        term
        transactions {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        type
        userid
      }
      personalInfo {
        address {
          address1
          address2
          city
          country
        }
        bank_accounts {
          account_no
          address {
            address1
            address2
            city
            country
          }
          bank_name
          branch_name
          deposit
          type
        }
        birthdate
        dateRegistered
        document_keys {
          dateUploaded
          documentType
          filename
          key
        }
        email
        employment {
          address {
            address1
            address2
            city
            country
          }
          fromDate
          hourly_rate
          job_title
          name
          net_salary
          pay_cycle
          private_sector
          toDate
        }
        family_name
        gender
        given_name
        home_number
        maritalStatus
        next_kin {
          name
          number
          relation
        }
        number_of_dependents
        outstanding_loan {
          amount
          institution
          isAvailable
          principal
          reflected_on_slip
        }
        phone_number
        social_media {
          handle
          name
        }
        userid
      }
    }
  }
`;
export const getCardData = /* GraphQL */ `
  query GetCardData($userid: ID!) {
    getCardData(userid: $userid) {
      BillingDetails {
        BillToAddress
        BillToAddress2
        BillToCity
        BillToCountry
        BillToCounty
        BillToEmail
        BillToFirstName
        BillToLastName
        BillToMobile
        BillToState
        BillToTelephone
        BillToZipPostCode
      }
      BinCheckResults {
        BIN
        Brand
        Country
        Issuer
        Subtype
        Type
      }
      CreditCardTransactionResults {
        AVSResult
        AuthCode
        CVV2Result
        OriginalResponseCode
        PaddedCardNumber
        ReasonCode
        ReasonCodeDescription
        ReferenceNumber
        ResponseCode
        TokenizedPAN
      }
      FraudControlResults {
        FraudControlId
        FraudResponseCode
        ReasonCode
        ReasonCodeDesc
        ResponseCode
        Score
      }
      IPGeoLocationResults {
        City
        CountryLong
        CountryShort
        DomainName
        IPAddress
        IPResponseCode
        IPResponseCodeDescription
        ISP
        Latitude
        Longitude
        Region
        TimeZone
        ZipCode
      }
      MerchantId
      OrderNumber
      ShippingDetails {
        ShipToAddress
        ShipToAddress2
        ShipToCity
        ShipToCountry
        ShipToCounty
        ShipToEmail
        ShipToFirstName
        ShipToLastName
        ShipToMobile
        ShipToState
        ShipToTelephone
        ShipToZipPostCode
      }
      Signature
      SignatureMethod
      ThreeDSResponse {
        AuthenticationResult
        CAVV
        ECIIndicator
        TransactionStain
      }
      userid
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($key: String) {
    getDocument(key: $key) {
      content
      contentType
      documentType
      filename
    }
  }
`;
export const getEmailTemplate = /* GraphQL */ `
  query GetEmailTemplate($templateName: ID!) {
    getEmailTemplate(templateName: $templateName) {
      dataList
      html
      subject
      templateName
      text
    }
  }
`;
export const getHostedPaymentUrl = /* GraphQL */ `
  query GetHostedPaymentUrl($amount: Float, $id: ID!) {
    getHostedPaymentUrl(amount: $amount, id: $id)
  }
`;
export const getLoan = /* GraphQL */ `
  query GetLoan($id: ID!) {
    getLoan(id: $id) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        loan_id
        reference
        type
      }
      type
      userid
    }
  }
`;
export const getLoanSettings = /* GraphQL */ `
  query GetLoanSettings {
    getLoanSettings {
      max_days_oneoff
      max_days_payday
      max_days_term
      min_days_oneoff
      min_days_payday
      min_days_term
      oneoff_active
      oneoff_max_amount
      oneoff_min_amount
      payday_daily_active
      payday_daily_interest_rate
      payday_daily_max_amount
      payday_daily_min_amount
      payday_oneoff_interest_rate
      paydays
      processing_fee
      term_active
      term_daily_interest_rate
      term_max_amount
      term_min_amount
      userType
    }
  }
`;
export const listAdminLoans = /* GraphQL */ `
  query ListAdminLoans {
    listAdminLoans {
      loans {
        amount_requested
        borrower_type
        comments
        currency
        date_applied
        date_approved
        date_first_payment_due
        date_issued
        date_required
        days
        disbusement_date
        fees
        id
        installment
        interests
        payment_method
        periodType
        possible_new_statuses
        principal
        profile_certified
        status
        term
        transactions {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        type
        userid
      }
      personalInfos {
        address {
          address1
          address2
          city
          country
        }
        bank_accounts {
          account_no
          address {
            address1
            address2
            city
            country
          }
          bank_name
          branch_name
          deposit
          type
        }
        birthdate
        dateRegistered
        document_keys {
          dateUploaded
          documentType
          filename
          key
        }
        email
        employment {
          address {
            address1
            address2
            city
            country
          }
          fromDate
          hourly_rate
          job_title
          name
          net_salary
          pay_cycle
          private_sector
          toDate
        }
        family_name
        gender
        given_name
        home_number
        maritalStatus
        next_kin {
          name
          number
          relation
        }
        number_of_dependents
        outstanding_loan {
          amount
          institution
          isAvailable
          principal
          reflected_on_slip
        }
        phone_number
        social_media {
          handle
          name
        }
        userid
      }
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates {
    listEmailTemplates {
      dataList
      html
      subject
      templateName
      text
    }
  }
`;
export const loadAssets = /* GraphQL */ `
  query LoadAssets {
    loadAssets {
      allLoans {
        amount_requested
        borrower_type
        comments
        currency
        date_applied
        date_approved
        date_first_payment_due
        date_issued
        date_required
        days
        disbusement_date
        fees
        id
        installment
        interests
        payment_method
        periodType
        possible_new_statuses
        principal
        profile_certified
        status
        term
        transactions {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        type
        userid
      }
      allPersonalInfos {
        address {
          address1
          address2
          city
          country
        }
        bank_accounts {
          account_no
          address {
            address1
            address2
            city
            country
          }
          bank_name
          branch_name
          deposit
          type
        }
        birthdate
        dateRegistered
        document_keys {
          dateUploaded
          documentType
          filename
          key
        }
        email
        employment {
          address {
            address1
            address2
            city
            country
          }
          fromDate
          hourly_rate
          job_title
          name
          net_salary
          pay_cycle
          private_sector
          toDate
        }
        family_name
        gender
        given_name
        home_number
        maritalStatus
        next_kin {
          name
          number
          relation
        }
        number_of_dependents
        outstanding_loan {
          amount
          institution
          isAvailable
          principal
          reflected_on_slip
        }
        phone_number
        social_media {
          handle
          name
        }
        userid
      }
      cardData {
        BillingDetails {
          BillToAddress
          BillToAddress2
          BillToCity
          BillToCountry
          BillToCounty
          BillToEmail
          BillToFirstName
          BillToLastName
          BillToMobile
          BillToState
          BillToTelephone
          BillToZipPostCode
        }
        BinCheckResults {
          BIN
          Brand
          Country
          Issuer
          Subtype
          Type
        }
        CreditCardTransactionResults {
          AVSResult
          AuthCode
          CVV2Result
          OriginalResponseCode
          PaddedCardNumber
          ReasonCode
          ReasonCodeDescription
          ReferenceNumber
          ResponseCode
          TokenizedPAN
        }
        FraudControlResults {
          FraudControlId
          FraudResponseCode
          ReasonCode
          ReasonCodeDesc
          ResponseCode
          Score
        }
        IPGeoLocationResults {
          City
          CountryLong
          CountryShort
          DomainName
          IPAddress
          IPResponseCode
          IPResponseCodeDescription
          ISP
          Latitude
          Longitude
          Region
          TimeZone
          ZipCode
        }
        MerchantId
        OrderNumber
        ShippingDetails {
          ShipToAddress
          ShipToAddress2
          ShipToCity
          ShipToCountry
          ShipToCounty
          ShipToEmail
          ShipToFirstName
          ShipToLastName
          ShipToMobile
          ShipToState
          ShipToTelephone
          ShipToZipPostCode
        }
        Signature
        SignatureMethod
        ThreeDSResponse {
          AuthenticationResult
          CAVV
          ECIIndicator
          TransactionStain
        }
        userid
      }
      isAdmin
      loans {
        amount_requested
        borrower_type
        comments
        currency
        date_applied
        date_approved
        date_first_payment_due
        date_issued
        date_required
        days
        disbusement_date
        fees
        id
        installment
        interests
        payment_method
        periodType
        possible_new_statuses
        principal
        profile_certified
        status
        term
        transactions {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        type
        userid
      }
      personalInfo {
        address {
          address1
          address2
          city
          country
        }
        bank_accounts {
          account_no
          address {
            address1
            address2
            city
            country
          }
          bank_name
          branch_name
          deposit
          type
        }
        birthdate
        dateRegistered
        document_keys {
          dateUploaded
          documentType
          filename
          key
        }
        email
        employment {
          address {
            address1
            address2
            city
            country
          }
          fromDate
          hourly_rate
          job_title
          name
          net_salary
          pay_cycle
          private_sector
          toDate
        }
        family_name
        gender
        given_name
        home_number
        maritalStatus
        next_kin {
          name
          number
          relation
        }
        number_of_dependents
        outstanding_loan {
          amount
          institution
          isAvailable
          principal
          reflected_on_slip
        }
        phone_number
        social_media {
          handle
          name
        }
        userid
      }
    }
  }
`;
export const loadLoans = /* GraphQL */ `
  query LoadLoans {
    loadLoans {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        loan_id
        reference
        type
      }
      type
      userid
    }
  }
`;
export const loadPersonalInfo = /* GraphQL */ `
  query LoadPersonalInfo {
    loadPersonalInfo {
      address {
        address1
        address2
        city
        country
      }
      bank_accounts {
        account_no
        address {
          address1
          address2
          city
          country
        }
        bank_name
        branch_name
        deposit
        type
      }
      birthdate
      dateRegistered
      document_keys {
        dateUploaded
        documentType
        filename
        key
      }
      email
      employment {
        address {
          address1
          address2
          city
          country
        }
        fromDate
        hourly_rate
        job_title
        name
        net_salary
        pay_cycle
        private_sector
        toDate
      }
      family_name
      gender
      given_name
      home_number
      maritalStatus
      next_kin {
        name
        number
        relation
      }
      number_of_dependents
      outstanding_loan {
        amount
        institution
        isAvailable
        principal
        reflected_on_slip
      }
      phone_number
      social_media {
        handle
        name
      }
      userid
    }
  }
`;
export const loadTransactions = /* GraphQL */ `
  query LoadTransactions {
    loadTransactions {
      amount
      cancelled
      confirmed
      created
      currency
      id
      linked_transaction {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
            }
            loan_id
            reference
            type
          }
          loan_id
          reference
          type
        }
        loan_id
        reference
        type
      }
      loan_id
      reference
      type
    }
  }
`;
