const production_hostname = "orendafinancialservices.com";
const dev_setting_url =
  "https://ap4twgabl3.execute-api.us-east-1.amazonaws.com/default/openda-client";
const prod_setting_url =
  "https://t2jjcqpwkb.execute-api.us-east-1.amazonaws.com/prod/openda-client";

const hostname = location.hostname;
const validHostnames = [production_hostname, `www.${production_hostname}`];
const is_prod = validHostnames.some((x) => x === hostname);

console.log("hostname", hostname);

export default {
  install(Vue) {
    Vue.VERSION = "v0.01";
    //is production
    Vue.prototype.$orendaIsProduction = is_prod;
    //get orenda public settings url
    Vue.prototype.$getOrendaSettingsUrl = function() {
      ///const code = Math.random() * (1000 - 999) + 999;
      return is_prod ? prod_setting_url : dev_setting_url; // + `?code=${code}`;
    };
  },
};
