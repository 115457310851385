const personalInfoData = {
  given_name: "",
  family_name: "",
  birthdate: "2000-01-01",
  address: {
    address1: "",
    address2: "",
    city: "",
    country: "Jamaica",
  },
  gender: "Male",
  phone_number: "",
  home_number: "",
  email: "",
  maritalStatus: null,
  next_kin: { name: "", number: "", relation: "" },
  social_media: [{ name: "", handle: "" }],
  employment: {
    name: "",
    fromDate: "",
    toDate: "",
    net_salary: null,
    job_title: "",
    pay_cycle: null,
    address: {
      address1: "",
      address2: "",
      city: "",
      country: "Jamaica",
    },
    private_sector: false,
    hourly_rate: null,
  },
  bank_accounts: [
    {
      salary: true,
      deposit: true,
      bank_name: "",
      branch_name: "",
      account_no: "",
      type: null,
    },
    {
      salary: false,
      deposit: true,
      bank_name: "",
      branch_name: "",
      account_no: "",
      type: null,
    },
  ],

  documents: [],

  number_of_dependents: 0,
  outstanding_loan: {
    isAvailable: false,
    reflected_on_slip: true,
    institution: "",
    principal: 0,
    amount: 0,
  },
};

const formaTabView = ({
  given_name,
  family_name,
  birthdate,
  gender,
  address,
  email,
  phone_number,

  home_number,
  maritalStatus,
  next_kin,
  social_media,
  number_of_dependents,
  outstanding_loan,

  employment: Employment,
  bank_accounts: Banking,
  document_keys: Documents,
}) => {
  const Personal = {
    given_name,
    family_name,
    birthdate,
    gender,
    maritalStatus,
    address,
    number_of_dependents,
  };

  const Contact = {
    phone_number,
    home_number,
    email,
    next_kin,
    social_media,
  };

  const Finance = { ...outstanding_loan };

  return {
    Personal,
    Contact,
    Employment,
    Finance,
    Banking,
    Documents,
  };
  /////////////////////////////////////
};

const checkPersonalInfo = ({
  given_name,
  family_name,
  birthdate,
  gender,
  address,
  /* email,
  phone_number,
  home_number, */
  maritalStatus,
  next_kin,

  employment,
  bank_accounts,
  document_keys,
}) => ({
  Name: given_name && family_name,
  BirthDate: birthdate,
  Gender: gender,
  MaritalStatus: maritalStatus,
  Address: address && address.address1,
  NextKin: next_kin && next_kin.name && next_kin.number,
  Employment: employment && employment.name,
  BankAccount: bank_accounts.length,
  Documents: document_keys.length,
});

export { formaTabView, personalInfoData, checkPersonalInfo };
