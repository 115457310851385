/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import Currency Component

import vueDebounce from "vue-debounce";
import PortalVue from 'portal-vue'

Vue.use(PortalVue)

Vue.use(vueDebounce);

import "./plugins";
// aws amplify  imports
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import AmplifySettings from "./aws-settings.js"; //aws-exports.js'
import "@aws-amplify/ui";

import orendaSettings from "./orenda-settings.js";
Vue.use(orendaSettings);

Amplify.configure(AmplifySettings);
Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
